export default [
  {
    title: "Home",
    route: "home",
    icon: "HomeIcon",
  },
  // {
  //   title: "Categories",
  //   icon: "AirplayIcon",

  //   tagVariant: "light-warning",
  //   children: [
  //     {
  //       title: "list All Categories",
  //       route: "list-categories",
  //     },
  //   ],
  // },
  {
    title: "courses",
    icon: "FileTextIcon",

    tagVariant: "light-warning",
    children: [
      // {
      //   title: "Add New",
      //   route: "add-course",
      // },
      // {
      //   title: "sections",
      //   route: "list-sections",
      // },
      {
        title: "list All Courses",
        route: "list-courses",
      },
    ],
  },
  // {
  //   title: "lessons",
  //   icon: "ListIcon",

  //   tagVariant: "light-warning",
  //   children: [
  //     {
  //       title: "list All lessons",
  //       route: "list-lessons",
  //     },
  //   ],
  // },
  // {
  //   title: "quizes",
  //   icon: "GridIcon",

  //   tagVariant: "light-warning",
  //   children: [
  //     {
  //       title: "Add quize",
  //       route: "add-quize",
  //     },
  //     {
  //       title: "list All quizes",
  //       route: "list-quizes",
  //     },
  //   ],
  // },
  // {
  //   title: "Questions",
  //   icon: "CreditCardIcon",

  //   tagVariant: "light-warning",
  //   children: [
  //     {
  //       title: "Add Question",
  //       route: "add-question",
  //     },
  //     {
  //       title: "list All Questions",
  //       route: "list-questions",
  //     },
  //   ],
  // },
  // // {
  // //   title: "Answers",
  // //   icon: "CheckSquareIcon",

  // //   tagVariant: "light-warning",
  // //   children: [
  // //     {
  // //       title: "list All Answers",
  // //       route: "list-answers",
  // //     },
  // //   ],
  // // },
  // {
  //   title: "Coupons",
  //   icon: "CheckSquareIcon",
  //   tagVariant: "light-warning",
  //   children: [
  //     {
  //       title: "list All Coupons",
  //       route: "list-coupons",
  //     },
  //   ],
  // },
  // {
  //   title: "orders",
  //   icon: "TrendingUpIcon",

  //   tagVariant: "light-warning",
  //   children: [
  //     {
  //       title: "list All orders",
  //       route: "list-orders",
  //     },
  //   ],
  // },
  // {
  //   title: "users",
  //   icon: "UsersIcon",

  //   tagVariant: "light-warning",
  //   children: [
  //     {
  //       title: "list All users",
  //       route: "list-users",
  //     },
  //   ],
  // },
];
