<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.name || userData.name }}
        </p>
        <span class="user-status">{{ userData.role }}</span>
      </div>
      <b-avatar
        size="40"
        :src="userData.profileImg"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon v-if="!userData.profileImg" icon="UserIcon" size="22" />
      </b-avatar>
    </template>

    <b-dropdown-item
      :to="{ name: 'account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span>Profile</span>
    </b-dropdown-item>
    <!--   <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="MailIcon" class="mr-50" />
      <span>Inbox</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-todo' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
      <span>Task</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-chat' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="MessageSquareIcon" class="mr-50" />
      <span>Chat</span>
    </b-dropdown-item> -->

    <b-dropdown-divider />

    <!-- <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
      <span>Settings</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'pages-pricing' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="CreditCardIcon" class="mr-50" />
      <span>Pricing</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'pages-faq' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="HelpCircleIcon" class="mr-50" />
      <span>FAQ</span>
    </b-dropdown-item> -->
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown
  >
</template>

<script>
import * as Cookies from "js-cookie";
import authApi from "@/apiServices/authApi";

import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
// import { initialAbility } from "@/libs/acl/config";
// import useJwt from '@/auth/jwt/useJwt'
// import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("user")),
      // avatarText,
    };
  },
  methods: {
    logout() {
      authApi
        .logout({
          email: this.userData.email,
          password: this.password,
        })
        .then((response) => {
          if (response && response.data) {
            Cookies.remove("token");
            Cookies.remove("user_data");
            localStorage.removeItem("user");

            this.$router.push({ name: "login" });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "bye bye  " + response.data.name,
                icon: "EditIcon",
                variant: "success",
              },
            });
          } else {
          }
        });
    },
  },
};
</script>
