import axiosApi from "../axios";
export default {
  async getUsersCart() {
    return await axiosApi
      .get(`/cart`)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },
  async addToUsersCart(courseId) {
    return await axiosApi
      .post(`/cart`, { courseId: courseId })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },
  async removeCartItem(courseId) {
    return await axiosApi
      .put(`/cart/`, {
        itemId: courseId,
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },
};
