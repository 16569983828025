<template>
  <b-nav-item-dropdown
    class="dropdown-cart mr-25"
    menu-class="dropdown-menu-media"
    right
    @show="getMyCart"
  >
    <template #button-content>
      <feather-icon
        :badge="$store.state['cart'].cartItemsCount"
        class="text-body"
        icon="ShoppingCartIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">My Cart</h4>
        <b-badge pill variant="light-primary">
          {{ $store.state["cart"].cartItemsCount }} Items
        </b-badge>
      </div>
    </li>

    <!-- Cart Items -->
    <vue-perfect-scrollbar
      v-if="items.length"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-media v-for="item in items" :key="item.name">
        <template #aside>
          <b-img :src="item.imageCover" :alt="item.name" rounded width="70px" />
        </template>
        <feather-icon
          icon="XIcon"
          class="cart-item-remove cursor-pointer"
          @click.stop="removeItemFromCart(item)"
        />
        <div class="media-heading">
          <h6 class="cart-item-title">
            <b-link class="text-body">
              {{ item.title }}
            </b-link>
          </h6>
          <!-- <small class="cart-item-by">By {{ item.brand }}</small> -->
        </div>

        <div class="cart-item-qty ml-1">
          <!-- <b-form-spinbutton v-model="item.qty" min="1" size="sm" /> -->
        </div>

        <h5 class="cart-item-price">
          ${{ item.priceAfterDiscount || item.price }}
        </h5>
      </b-media>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li v-if="items.length" class="dropdown-menu-footer">
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">Total:</h6>
        <h6 class="text-primary font-weight-bolder mb-0">${{ totalAmount }}</h6>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        :to="{ name: 'checkout' }"
      >
        Checkout
      </b-button>
    </li>

    <p v-if="!items.length" class="m-0 p-1 text-center">Your cart is empty</p>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BImg,
  BFormSpinbutton,
  BButton,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import cartApi from "@/apiServices/cartApi";
import { mapState, mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BImg,
    BFormSpinbutton,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    };
  },
  created() {
    // this.getMyCart();
  },
  computed: {
    totalAmount() {
      let total = 0;
      this.items.forEach((i) => {
        total += i.priceAfterDiscount || i.price;
      });
      return total;
    },
  },
  methods: {
    ...mapActions("cart", ["removeCourseFromCart", "addCourseInCart"]),

    getMyCart() {
      cartApi.getUsersCart().then((response) => {
        if (response.data) {
          console.log(response.data);
          this.items = response.data?.cartItems;
          this.items.forEach((element) => {
            this.addCourseInCart(element);
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "your cart is empty",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },

    async removeItemFromCart(course) {
      await cartApi.removeCartItem(course._id).then((response) => {
        if (response.data) {
          console.log(response.data);
          this.removeCourseFromCart(course);
          this.items = response.data?.cartItems;
        } else {
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
